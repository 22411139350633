import React, { useState } from "react";
import AddReviewForm from "./AddReviewForm.tsx";
import Reviews from "./Reviews.tsx";

const CompanyReviewsSection: React.FC<{ companyId: string }> = ({ companyId }) => {
  const [refresh, setRefresh] = useState(false);

  const handleReviewAdded = () => {
    setRefresh(!refresh);
  };

  return (
    <div>
      <h2>Отзывы</h2>
      <AddReviewForm companyId={companyId} onReviewAdded={handleReviewAdded} />
      <Reviews companyId={companyId} key={refresh ? 'refresh-true' : 'refresh-false'} />
    </div>
  );
};

export default CompanyReviewsSection;
