import React from "react";
import { SeoArticleProps } from "../types";

const SeoArticle: React.FC<SeoArticleProps> = ({
  location = "в вашем городе",
  serviceType = "на дом",
  priceFrom = 1500,
}) => {
  return (
    <section className="block">
      <h2 className="subtitle">
        Заказать {location} {serviceType}
      </h2>
      <article>
        <p className="article_text">
          Сделайте свой балкон уютным и функциональным — закажите услуги по ремонту балконов
          {location}. Наши опытные специалисты приедут {serviceType},
          чтобы выполнить все необходимые работы качественно и в срок. Мы занимаемся
          ремонтом балконов любой сложности, от остекления до утепления и отделки.
        </p>
        <p className="article_text">
          Ремонт балкона — это важный шаг к улучшению вашего жилья. Наши мастера
          помогут превратить ваш балкон в уютное и полезное пространство. Закажите услуги
          по ремонту балкона {serviceType} для создания комфортного и красивого места отдыха
          {location}!
        </p>
        <p className="article_text">
          Обеспечьте себе комфортный и безопасный балкон с помощью профессиональных мастеров.
          Оформите заказ на нашем сайте и выберите удобное для вас время. Мы готовы
          выполнить все необходимые работы для ремонта балкона. Получите качественное
          обслуживание уже сегодня — выберите подходящего специалиста прямо сейчас!
        </p>
        <h3 className="subtitle">
          Сколько стоит {location} {serviceType}?
        </h3>
        <p className="article_text">
          Стоимость {location} {serviceType} начинается от {priceFrom} рублей.
          Цена зависит от объема работ, используемых материалов и сложности ремонта.
          Мы предлагаем различные пакеты услуг, чтобы каждый мог выбрать подходящий вариант
          в зависимости от своих нужд и бюджета. Рекомендуем обращаться заранее, чтобы выбрать
          удобное время и оптимальный пакет услуг!
        </p>
      </article>
    </section>
  );
};

export default SeoArticle;
