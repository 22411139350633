import React, { useEffect, useState } from "react";
import { Card, Spin, Modal, message, Carousel } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { fetchUserSantas, deleteSantaById } from "../utils/api.ts";
import { Santa } from "../types";

type SantaItem = Santa | { isAddCard: boolean };

const UserSantaList = ({
  onAddSantaClick,
}: {
  onAddSantaClick: (value: string) => void;
}) => {
  const [santas, setSantas] = useState<Santa[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadSantas = async () => {
      try {
        const santasData = await fetchUserSantas();
        setSantas(santasData);
      } catch (error) {
        console.error("Ошибка загрузки данных:", error);
      } finally {
        setLoading(false);
      }
    };

    loadSantas();
  }, []);

  const handleDeleteSanta = async (santaId: string) => {
    try {
      await deleteSantaById(santaId);
      setSantas((prevSantas) =>
        prevSantas.filter((santa) => santa._id !== santaId)
      );
      message.success("Успешно удалёно");
    } catch (error) {
      message.error("Ошибка при удалении");
    }
  };

  const confirmDelete = (santaId: string) => {
    Modal.confirm({
      title: "Вы уверены, что хотите удалить ?",
      onOk: () => handleDeleteSanta(santaId),
      okText: "Удалить",
      cancelText: "Отмена",
    });
  };

  if (loading) {
    return <Spin size="large" />;
  }

  const dataSource: SantaItem[] = [{ isAddCard: true }, ...santas];

  return (
    <div>
      <div className="profile_santa_list text">
        {dataSource.map((item, index) =>
          "isAddCard" in item ? (
            <div
              key={index}
              className="profile_santa_card_item profile_santa_card_add"
              onClick={() => onAddSantaClick("2")}
            >
              <PlusOutlined style={{ fontSize: "48px", padding: "20px" }} />
              <p className="profile_santa_card_add_text">Добавить компанию</p>
            </div>
          ) : (
            <div key={index} className="profile_santa_card_item">
              <Card
                title={item.name}
                className="profile_santa_card_item text"
                actions={[
                  <span></span>,
                  <span onClick={() => confirmDelete(item._id)}>Удалить</span>,
                ]}
              >
                <Carousel arrows>
                  {item.images.map((image, imageIndex) => (
                    <div key={imageIndex}>
                      <img
                        src={image}
                        alt={`${imageIndex + 1}`}
                        className="profile_santa_card_image"
                        loading="lazy"
                      />
                    </div>
                  ))}
                </Carousel>

                <span>Рейтинг: {item.rating}</span>
                <p>Цена: {item.price}</p>
                <p>Телефон: {item.telephone}</p>
              </Card>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default UserSantaList;
