import axios from "axios";
import { Santa, CitiesResponse } from "../types";

export const fetchObjects = async (): Promise<Santa[]> => {
  const response = await axios.get<Santa[]>(`/api/objects`);
  return response.data;
};

export const fetchCities = async (): Promise<CitiesResponse> => {
  const response = await axios.get<CitiesResponse>(`/api/cities`);
  return response.data;
};

export const login = (credentials) => {
  return axios.post("/api/auth/login", credentials);
};

export const register = (credentials) => {
  return axios.post("/api/auth/register", credentials);
};

export const fetchSantaProfile = async () => {
  const token = localStorage.getItem("token");

  if (!token) {
    throw new Error("User is not authenticated");
  }

  const response = await axios.get("/api/objects/me", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const updateSantaProfile = async (values) => {
  const token = localStorage.getItem("token");

  if (!token) {
    throw new Error("User is not authenticated");
  }

  const response = await axios.put("/api/objects/update", values, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const addSanta = async (santaData) => {
  const token = localStorage.getItem("token");

  if (!token) {
    throw new Error("User is not authenticated");
  }

  const response = await axios.post("/api/objects/", santaData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const validateToken = async () => {
  const token = localStorage.getItem("token");
  if (!token) throw new Error("Token not found");

  try {
    const response = await axios.get("/api/auth/validate", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Invalid or expired token");
  }
};

export const fetchUserSantas = async () => {
  const token = localStorage.getItem("token");

  if (!token) {
    throw new Error("User is not authenticated");
  }
  try {
    const response = await axios.get("/api/objects/user-objects", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Ошибка загрузки данных:", error);
    throw error;
  }
};

export async function deleteSantaById(santaId: string): Promise<void> {
  const token = localStorage.getItem("token");

  if (!token) {
    throw new Error("User is not authenticated");
  }

  try {
    await axios.delete(`/api/objects/${santaId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error("Ошибка при удалении объекта:", error);
    throw error;
  }
}

export const fetchObjectById = async (id: string) => {
  try {
    const response = await axios.get(`/api/objects/${id}`);
    return response.data;
  } catch (error: any) {
    console.error('Ошибка при получении данных объекта:', error);
    throw new Error(error.response?.data?.message || 'Не удалось загрузить данные объекта');
  }
};