import { configureStore } from '@reduxjs/toolkit';
import filterReducer from './slices/filterSlice.ts';
import cityReducer from './slices/citySlice.ts';
import objectReducer from './slices/objectSlice.ts';

const store = configureStore({
  reducer: {
    filters: filterReducer,
    cities: cityReducer,
    objects: objectReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
