import React from "react";
import { Collapse } from "antd";

const faqData = [
    {
        key: "1",
        label: "Какие виды ремонта балконов вы предлагаете?",
        children:
            "Мы предлагаем широкий спектр услуг по ремонту балконов, включая остекление, утепление, отделку, установку защитных экранов и много другое.",
    },
    {
        key: "2",
        label: "Как заказать ремонт балкона?",
        children:
            "Вы можете заказать ремонт балкона через наш сайт, выбрав удобную дату и время. После оформления заказа мы свяжем вас с подходящим специалистом.",
    },
    {
        key: "3",
        label: "Какие материалы используются при ремонте балкона?",
        children:
            "Мы используем только качественные и надежные материалы, подходящие для внешних и внутренних работ на балконе. Тип материалов можно обсудить с мастером при заказе.",
    },
    {
        key: "4",
        label: "Какова стоимость ремонта балкона?",
        children:
            "Стоимость ремонта зависит от объема и сложности работ, а также выбранных материалов. Рекомендуем обсудить детали с выбранным мастером.",
    },
    {
        key: "5",
        label: "Как выбрать подходящего специалиста для ремонта балкона?",
        children:
            "На нашем сайте вы можете просмотреть профили мастеров, ознакомиться с их опытом, отзывами клиентов и рейтингами, чтобы выбрать подходящего специалиста.",
    },
    {
        key: "6",
        label: "Есть ли у вас мастера по ремонту балконов в моем городе?",
        children:
            "Да, у нас есть мастера в разных городах. Вы можете выбрать нужный город при поиске на нашем сайте.",
    },
    {
        key: "7",
        label: "Можно ли заказать услуги ремонта на короткий срок?",
        children:
            "Да, вы можете заказать ремонт на один или несколько дней, в зависимости от объема работ.",
    },
    {
        key: "8",
        label: "Как отменить заказ на ремонт?",
        children:
            "Если вам нужно отменить заказ, свяжитесь с выбранным мастером через нашу платформу для согласования отмены.",
    },
    {
        key: "9",
        label: "Что делать, если мастер опоздал?",
        children:
            "В случае опоздания мастера, пожалуйста, свяжитесь с ним напрямую через нашу платформу для уточнения ситуации.",
    },
    {
        key: "10",
        label: "Можно ли указать особые пожелания к ремонту?",
        children:
            "Да, вы можете указать любые особые требования при заказе, и мы передадим их мастеру.",
    },
    {
        key: "11",
        label: "Как оплатить услуги ремонта балкона?",
        children:
            "Оплата производится напрямую с выбранным мастером. Обсудите удобные для вас способы оплаты перед началом работы.",
    },
];

const FAQ: React.FC = () => {
    return (
        <div className="block">
            <h2 className="subtitle">Часто задаваемые вопросы</h2>
            <Collapse accordion items={faqData} className="text" />
        </div>
    );
};

export default FAQ;
