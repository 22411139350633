import React, { useState, useEffect } from "react";
import {
  TeamOutlined,
  UserAddOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, theme } from "antd";
import type { MenuProps } from "antd";
import useAuthRedirect from "../hooks/useAuthRedirect.js";
import AddSanta from "../components/AddObject.tsx";
import UserSantaList from "../components/UserSantaList.tsx";

const { Header, Content, Sider } = Layout;

type MenuItem = {
  label: React.ReactNode;
  key: React.Key;
  icon?: React.ReactNode;
  children?: MenuItem[];
};

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items: MenuItem[] = [
  getItem("Компании", "1", <TeamOutlined />),
  getItem("Добавить", "2", <UserAddOutlined />),
];

const Profile: React.FC = () => {
  const isAuthenticatedChecked = useAuthRedirect("/логин/");
  const [collapsed, setCollapsed] = useState(true);
  const [selectedKey, setSelectedKey] = useState("1");
  const [isMobile, setIsMobile] = useState(false);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onClick: MenuProps["onClick"] = (e) => {
    setSelectedKey(e.key);
  };

  const handleAddSantaClick = (value) => {
    setSelectedKey(value);
  };

  const renderContent = () => {
    switch (selectedKey) {
      case "1":
        return <UserSantaList onAddSantaClick={handleAddSantaClick} />;
      case "2":
        return <AddSanta onAddSantaClick={handleAddSantaClick}/>;
      default:
        return <UserSantaList onAddSantaClick={handleAddSantaClick} />;
    }
  };

  const getSelectedTitle = () => {
    const selectedItem = items.find((item) => item?.key === selectedKey);
    return selectedItem ? selectedItem.label : "Профиль";
  };

  return (
    <Layout className="profile">
      {isMobile ? (
        <Menu
          onClick={onClick}
          defaultSelectedKeys={["1"]}
          mode="horizontal"
          items={items}
        />
      ) : (
        <Sider
          style={{ background: colorBgContainer }}
          className="sider"
          trigger={null}
          collapsible
          collapsed={collapsed}
        >
          <Menu
            onClick={onClick}
            defaultSelectedKeys={["1"]}
            mode="inline"
            className="menu"
            style={{ background: colorBgContainer }}
            items={items}
          />
        </Sider>
      )}
      <Layout className="profile_content_layout">
        <Header
          style={{ background: colorBgContainer }}
          className="profile_header"
        >
          {!isMobile && (
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />
          )}
          <h2 className="profile_subtitle">{getSelectedTitle()}</h2>
        </Header>
        <Content className="profile_content">{renderContent()}</Content>
      </Layout>
    </Layout>
  );
};

export default Profile;
