import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { Santa } from '../../types';

export const fetchObjects = createAsyncThunk('objects/fetchObjects', async () => {
  const response = await axios.get<Santa[]>('/api/objects');
  return response.data;
});

interface ObjectsState {
  items: Santa[];
  loading: boolean;
  error: string | null;
}

const initialState: ObjectsState = {
  items: [],
  loading: false,
  error: null,
};

const objectsSlice = createSlice({
  name: 'objects',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchObjects.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchObjects.fulfilled, (state, action: PayloadAction<Santa[]>) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(fetchObjects.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Ошибка при загрузке данных';
      });
  },
});

export default objectsSlice.reducer;
