import React, { useState } from "react";
import { Form, Input, Rate, Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";

const AddReviewForm: React.FC<{ companyId: string; onReviewAdded: () => void }> = ({ companyId, onReviewAdded }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: any) => {
    const formData = new FormData();
    formData.append("companyId", companyId);
    formData.append("rating", values.rating);
    formData.append("comment", values.comment);
    if (values.images) {
      values.images.fileList.forEach((file: any) => formData.append("images", file.originFileObj));
    }

    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      await axios.post("/api/reviews", formData, {
        headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${token}` },
      });
      message.success("Отзыв добавлен!");
      onReviewAdded();
    } catch (err) {
      message.error("Ошибка при добавлении отзыва");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit}>
      <Form.Item name="rating" label="Оценка" rules={[{ required: true, message: "Пожалуйста, выберите оценку" }]}>
        <Rate />
      </Form.Item>
      <Form.Item
        name="comment"
        label="Комментарий"
        rules={[{ required: true, message: "Пожалуйста, введите комментарий" }]}
      >
        <Input.TextArea rows={3} />
      </Form.Item>
      <Form.Item name="images" label="Фотографии">
        <Upload listType="picture" maxCount={5} beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Добавить фото</Button>
        </Upload>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Отправить отзыв
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddReviewForm;
