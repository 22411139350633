import React, { useEffect, useState } from "react";
import { List, Rate, Avatar, Image, Spin } from "antd";
import axios from "axios";

const Reviews: React.FC<{ companyId: string }> = ({ companyId }) => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get(`/api/reviews/${companyId}`);
        setReviews(response.data);
      } catch (err) {
        console.error("Ошибка при загрузке отзывов", err);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, [companyId]);

  if (loading) {
    return <Spin />;
  }

  return (
    <List
      itemLayout="vertical"
      dataSource={reviews}
      renderItem={(review: any) => (
        <List.Item
          key={review._id}
          extra={
            review.images.length > 0 && (
              <div>
                {review.images.map((img: string, index: number) => (
                  <Image
                    key={index}
                    src={img}
                    alt={`Фото ${index + 1}`}
                    width={100}
                  />
                ))}
              </div>
            )
          }
        >
          <List.Item.Meta
            // avatar={<Avatar>{review.userId.name[0]}</Avatar>}
            title={review.userId.name}
            description={<Rate disabled value={review.rating} />}
          />
          {review.comment}
        </List.Item>
      )}
    />
  );
};

export default Reviews;
