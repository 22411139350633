import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CompanyReviewsSection from "../components/CompanyReviewsSection.tsx"
import { Card, Typography, Spin, Alert, Rate, Row, Col, Image } from "antd";
import { fetchObjectById } from "../utils/api.ts";
import { Santa } from "../types";

const { Title, Paragraph } = Typography;

const CompanyPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [company, setCompany] = useState<Santa | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        setLoading(true);
        const response = await fetchObjectById(id!);
        setCompany(response);
      } catch (err: any) {
        setError(
          err.response?.data?.message || "Ошибка при загрузке данных компании"
        );
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchCompany();
    }
  }, [id]);

  if (loading) {
    return (
      <div className="company-page p-6">
        <Spin />
      </div>
    );
  }

  if (error) {
    return (
      <div className="company-page p-6">
        <Alert message="Ошибка" description={error} type="error" showIcon />
      </div>
    );
  }

  if (!company || !id) {
    return (
      <div className="company-page p-6">
        <Alert message="Компания не найдена" type="warning" showIcon />
      </div>
    );
  }

  return (
    <div className="block">
      <Card>
        <Title level={2}>{company.name}</Title>
        <div className="mt-4">
          <Title level={4}>Рейтинг</Title>
          <Rate disabled value={company.rating} />
        </div>
        <div className="mt-4">
          <Title level={4}>Контакты</Title>
          <Paragraph>
            Телефон: <a href={`tel:${company.telephone}`}>{company.telephone}</a>
          </Paragraph>
          <Paragraph>Стоимость: от {company.price} руб.</Paragraph>
        </div>
        {company.images && company.images.length > 0 && (
          <div className="mt-6">
            <Title level={4}>Галерея</Title>
            <Row gutter={[16, 16]}>
              {company.images.map((image, index) => (
                <Col key={index} xs={24} sm={12} lg={8}>
                  <Image src={image} alt={`Фото ${index + 1}`} />
                </Col>
              ))}
            </Row>
          </div>
        )}
      </Card>
      <div className="block mt-2">
        <CompanyReviewsSection companyId={id}/>
      </div>
    </div>
  );
};

export default CompanyPage;
