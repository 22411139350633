import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Select, Button, Row, Col, Card } from "antd";
import {
  SearchOutlined,
  ClockCircleOutlined,
  SmileOutlined,
  CustomerServiceOutlined,
  ToolOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

const MainPage: React.FC = () => {
  const navigate = useNavigate();
  const cities = useSelector((state: RootState) => state.cities.cities);

  const handleSearch = (value: string) => {
    navigate(`/листинг/${value}`);
  };

  useEffect(() => {
    if(document.title.length > 0){
      (window as any).YandexRotorSettings.IsLoaded = true;
    }
  }, []);

  return (
    <div className="main-page">
      <h1 className="title">Агрегатор компаний по ремонту балконов в Москве</h1>
      <div className="section main_first_block">
        <img src="/images/balcony-repair.webp" alt="Ремонт балконов" loading="lazy" className="main_image" />
        <div className="main_block_content">
          <p className="main_first_block_text">
            Найдите надежную компанию для ремонта вашего балкона. 
            Наш агрегатор предоставляет информацию о лучших специалистах, которые качественно 
            и профессионально выполнят все виды работ. Обеспечьте долговечность и безопасность вашего балкона.
          </p>
          <Select
            showSearch
            placeholder="Выберите услугу"
            optionFilterProp="children"
            filterSort={(optionA, optionB) =>
              (optionA?.name ?? "").toLowerCase().localeCompare((optionB?.name ?? "").toLowerCase())
            }
            onChange={handleSearch}
            className="search text"
          >
            {cities.map((city) => (
              <Select.Option key={city.id} value={city.name}>
                {city.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>

      <div className="section text">
        <h2 className="subtitle">Наши услуги</h2>
        <div className="card-container">
          <div className="card">
            <img
              src="/images/balcony-home.webp"
              alt="Ремонт балкона под ключ"
              className="card-image"
              loading="lazy"
            />
            <h3 className="h3">Ремонт под ключ</h3>
            <p>
              Полный ремонт и отделка балкона с нуля, включая утепление, остекление и отделочные работы.
            </p>
            <Button
              type="primary"
              onClick={() => navigate("/листинг/под ключ")}
            >
              Выбрать
            </Button>
          </div>
          <div className="card">
            <img
              src="/images/balcony-hospital.webp"
              alt="Утепление балкона"
              className="card-image"
              loading="lazy"
            />
            <h3 className="h3">Утепление балкона</h3>
            <p>
              Профессиональное утепление балкона для комфортного использования в холодное время года.
            </p>
            <Button
              type="primary"
              onClick={() => navigate("/листинг/Утепление%20балкона")}
            >
              Выбрать
            </Button>
          </div>
          <div className="card">
            <img
              src="/images/balcony-home.webp"
              alt="Остекление балкона"
              className="card-image"
              loading="lazy"
            />
            <h3 className="h3">Остекление</h3>
            <p>
              Качественное остекление балкона для защиты от непогоды и улучшения звукоизоляции.
            </p>
            <Button
              type="primary"
              onClick={() => navigate("/листинг/Ремонт%20остекления%20балкона")}
            >
              Выбрать
            </Button>
          </div>
        </div>
      </div>

      <div className="section advantages">
        <h2 className="subtitle">Почему выбирают нас</h2>
        <Row gutter={[16, 16]} className="advantages-list">
          <Col xs={24} sm={12} md={8}>
            <Card bordered={false}>
              <SearchOutlined style={{ fontSize: "36px", color: "#1890ff" }} />
              <h3 className="h3">Удобный поиск услуг</h3>
              <p className="text">
                Найдите нужную услугу и подходящего специалиста за несколько минут.
              </p>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card bordered={false}>
              <ClockCircleOutlined style={{ fontSize: "36px", color: "#1890ff" }} />
              <h3 className="h3">Своевременное выполнение</h3>
              <p className="text">
                Наши компании гарантируют выполнение работ в оговоренные сроки.
              </p>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card bordered={false}>
              <ToolOutlined style={{ fontSize: "36px", color: "#1890ff" }} />
              <h3 className="h3">Профессиональные мастера</h3>
              <p className="text">
                Мы сотрудничаем только с квалифицированными специалистами.
              </p>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card bordered={false}>
              <SmileOutlined style={{ fontSize: "36px", color: "#1890ff" }} />
              <h3 className="h3">Высокое качество работы</h3>
              <p className="text">
                Контроль качества на каждом этапе выполнения работ.
              </p>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card bordered={false}>
              <HomeOutlined style={{ fontSize: "36px", color: "#1890ff" }} />
              <h3 className="h3">Удобное бронирование</h3>
              <p className="text">
                Легкость в выборе и бронировании услуг по ремонту.
              </p>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Card bordered={false}>
              <CustomerServiceOutlined style={{ fontSize: "36px", color: "#1890ff" }} />
              <h3 className="h3">Круглосуточная поддержка</h3>
              <p className="text">
                Мы всегда готовы ответить на ваши вопросы и помочь с выбором.
              </p>
            </Card>
          </Col>
        </Row>
      </div>

      <div className="section seo-text block">
        <h2 className="subtitle">Найдите лучшие компании для ремонта балконов</h2>
        <p className="text">
          Наш агрегатор поможет вам выбрать надежных специалистов для ремонта вашего балкона. 
          Независимо от вашего местоположения, будь то Москва, Санкт-Петербург или любой другой город, 
          вы найдете квалифицированных мастеров, готовых предложить свои услуги.
        </p>
      </div>
    </div>
  );
};

export default MainPage;
